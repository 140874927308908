import { useState } from 'react';
import { Typography, Button, notification, Modal, Space, Input, Select } from 'antd';
import { useQuery } from '@apollo/client';

import { TVP_Final_Program } from '../../graphql/types';
import { GET_TVP_FINAL_PROGRAMS, GET_TVP_DRAFT_PROGRAMS} from '../../graphql/queries';

import config from '../../config';
import {
  useCopyTvpFinalToDraftProgramMutation,
  useRetireTvpFinalProgramMutation
} from '../../hooks/useMutations';

import TvpFinalProgramTable from '../../components/TvpFinalProgramTable';

import TvpFinalProgramFormModal from '../../components/TvpFinalProgramFormModal';

function TvpFinal() {
 
   // @ts-ignore
   const idTokenClaims = JSON.parse(localStorage.getItem(config.tokenClaimsKey));
   const userEmail = idTokenClaims.preferred_username;

  const [showLatestYear, setShowLatestYear] = useState(false);

  const [copyingToTvpDraftProgram, setCopyingToTvpDraftProgram] = useState<TVP_Final_Program | undefined>(undefined);
  const [copyingConfirmationVisible, setCopyingConfirmationVisible] = useState(false);


  const [visibleProgramForm, setVisibleProgramForm] = useState(false);
  const [editingTvpFinalProgram, setEditingTvpFinalProgram] = useState<TVP_Final_Program | undefined>(undefined);


  const { loading, error, data } = useQuery(GET_TVP_FINAL_PROGRAMS);

  const { refetch: refetchTvpDraft } = useQuery(GET_TVP_DRAFT_PROGRAMS);


  const showModal = (editingTvpFinalProgram?: TVP_Final_Program) => {
    setEditingTvpFinalProgram(editingTvpFinalProgram);
    setVisibleProgramForm(true);
  };

  const handleUpdate = async (values: TVP_Final_Program) => {
    // try {
    //   const { data } = await updateTvpFinalProgram({
    //     variables: { newTvpFinalProgram: values },
    //   });
    //   if (data?.updateTvpFinalProgram) {
    //     setVisibleProgramForm(false);
    //     notification.success({ message: 'Update successful!' });
    //   }
    //   else {
    //     notification.error({ message: 'Update failed!' });
    //   }
    // } catch (error) {
    //   notification.error({ message: 'Update failed!' });
    // }
  };

  const [retiring, setRetiring] = useState<TVP_Final_Program | undefined>(undefined);
  const [retiringConfirmationVisible, setRetiringConfirmationVisible] = useState(false);
  const [retire] = useRetireTvpFinalProgramMutation();
  const [inputCommentValue, setInputCommentValue] = useState('');
  const confirmRetire = async () => {
    try {
      if (inputCommentValue ) {
        const { data } = await retire({
          variables: {
            Id: retiring?.Id || "",
            Annotation: inputCommentValue,
            Modifier: userEmail
          },
        });
        if (data?.retireTvpFinalProgram) {
          notification.success({ message: "Retiring successful!" });
        }
        else {
          notification.error({ message: "The program was not soft deleted!" });
        }
      }
      else {
        notification.error({ message: "The required information must be filled!" });
      }
    } catch (error) {
      notification.error({ message: "The program was not soft deleted!" });
    }
    setRetiringConfirmationVisible(false);
  };
  
  const handleRetire = async (currentTvpFinalProgram: TVP_Final_Program) => {
    setRetiring(currentTvpFinalProgram);
    setRetiringConfirmationVisible(true);
  };

  const [copyTvpFinalToDraft] = useCopyTvpFinalToDraftProgramMutation();
  const currentYear = new Date().getFullYear();
  const startYear = Math.max(2021, currentYear - 4); // Ensure startYear is no earlier than 2021

  const fisicalYearOptions = Array.from({ length: 8 }, (_, index) => ({
    value: (startYear + index).toString() + "-04-01",
    label: (startYear + index).toString() + "-04-01"
  }));

  const [inputFiscalYearValue, setInputFiscalYearValue] = useState('');
  const confirmCopy = async () => {
    try {
      if (inputFiscalYearValue ) {
        const { data } = await copyTvpFinalToDraft({
          variables: {
            Id: copyingToTvpDraftProgram?.Id || "",
            FiscalYearStartDate: inputFiscalYearValue,
            Creator: userEmail
          },
        });
        if (data?.copyTvpFinalToDraftProgram) {
          notification.success({ message: "Copying successful!" });
        }
        else {
          notification.error({ message: "The program was not copied!" });
        }
      }
      else {
        notification.error({ message: "The required information must be filled!" });
      }
    } catch (error) {
      notification.error({ message: "The program was not copied!" });
    }
    setCopyingConfirmationVisible(false);
  };
  const handleCopyToDraft = async (currentTvpFinalProgram: TVP_Final_Program) => {
    const ProgramName = currentTvpFinalProgram.ProgramName;
  
    try {
      // Explicitly refetch the draft data from the server
      const { data: freshDraftData } = await refetchTvpDraft();
  
      if (freshDraftData) {
        const freshTvpDraftPrograms = freshDraftData.tvpDraftPrograms || [];
  
       // Check if the program name is duplicated in the fresh draft data
       const isDuplicated = freshTvpDraftPrograms.some((program: { ProgramName: string; }) => program.ProgramName === ProgramName);
  
        if (isDuplicated) {
          // Display a confirmation modal
          Modal.confirm({
            title: 'Duplicate Program Name',
            content: `A program with the name "${ProgramName}" already exists in the draft. Do you still want to copy it?`,
            okText: 'Copy Anyway',
            cancelText: 'Cancel',
            onOk: () => {
              // User confirmed, proceed with copying
              setCopyingToTvpDraftProgram(currentTvpFinalProgram);
              setCopyingConfirmationVisible(true);
            },
          });
        } else {
          // No duplication, proceed with copying directly
          setCopyingToTvpDraftProgram(currentTvpFinalProgram);
          setCopyingConfirmationVisible(true);
        }
      } else {
        notification.error({ message: 'Failed to fetch fresh draft data.' });
      }
    } catch (error) {
      notification.error({ message: 'An error occurred while fetching fresh draft data.' });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const tvpFinalPrograms = data?.tvpFinalPrograms ?? [];

  // Calculate today's date
  //const today = new Date().toISOString().split('T')[0];

  // Function to filter programs by the latest year
  const filterProgramsByLatestYear = (programs: any[]) => {
    if (!showLatestYear) {
      return programs; // If showLatestYear is false, return all programs
    }

    const latestDate = tvpFinalPrograms.reduce((latest:any, program:any) => {
      if (program.FiscalYearStartDate > latest) {
        return program.FiscalYearStartDate;
      }
      return latest;
    }, '1900-01-01'); // Initialize with a very old date
    // Filter programs based on the latest date
    return tvpFinalPrograms.filter((program: any) => program.FiscalYearStartDate === latestDate);
  };

  // Apply the filter to the programs
  const filteredTvpFinalPrograms = filterProgramsByLatestYear(tvpFinalPrograms);


  return (
    <div>
      <Typography.Title level={3}>
        List of Targeted Verification Programs (Published)
      </Typography.Title>
      <div className="site-layout-content">
      <Button onClick={() => setShowLatestYear(!showLatestYear)}>
        {showLatestYear ? 'Show All FY Years' : 'Show Latest FY Year Only'}
      </Button>

        <TvpFinalProgramTable
          tvpFinalPrograms={filteredTvpFinalPrograms}
          onRead={showModal}
          onCopyToDraft={handleCopyToDraft}
          onRetire={handleRetire}
         
        />
        <Modal
          open={copyingConfirmationVisible}
          title="To make a copy to draft table, please enter the following info."
          okText="Copy to draft table"
          cancelText="Cancel"
          onCancel={() => setCopyingConfirmationVisible(false)}
          onOk={confirmCopy}
          maskClosable={false}
        >
          <div style={{ marginBottom: '6px', paddingLeft: '16px' }}>
            <Space>
              <Typography.Text strong>Fiscal Year Start Date:</Typography.Text>
              {'     '}
              <Select
                style={{ width: 240 }}
                options={fisicalYearOptions} 
                onChange={(value) => setInputFiscalYearValue(value)}/>
            </Space>
          </div>
          
        </Modal>
        <Modal
          open={retiringConfirmationVisible}
          title="To retire this program, please enter the following info."
          okText="Retire"
          okButtonProps={{ danger: true }}
          cancelText="Cancel"
          onCancel={() => setRetiringConfirmationVisible(false)}
          onOk={confirmRetire}
          maskClosable={false}
        >
          <div style={{ marginBottom: '6px', paddingLeft: '16px' }}>
            <Space>
              <Typography.Text strong>Reason to retire the program:</Typography.Text>
              {'     '}
              <Input value={inputCommentValue} style={{ width: 250 }} onChange={(e) => setInputCommentValue(e.target.value)} />
            </Space>
          </div>
          
        </Modal>

        {visibleProgramForm && (
          <TvpFinalProgramFormModal
            visible={visibleProgramForm}
            initialValues={editingTvpFinalProgram as TVP_Final_Program}
            // onSubmit={handleUpdate}
            onSubmit={handleUpdate}
            onCancel={() => setVisibleProgramForm(false)}
          />
        )}
       
      </div>
    </div>
  );

}
export default TvpFinal