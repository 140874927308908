// components/UserTable.tsx
import React from 'react';
import { Table, Button, Space } from 'antd';
import { User } from '../graphql/types';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

type UserTableProps = {
  users: User[];
  onEdit: (user: User) => void;
  onDelete: (user: User) => void;
};

const UserTable: React.FC<UserTableProps> = ({ users, onEdit, onDelete }) => {
  const columns = [
    {
      title: 'Stratum',
      dataIndex: 'user_name',
      key: 'user_name',
    },
    {
      title: 'Id',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Lat',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: 'Long',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: User) => (
        <Space>
          <Button
            type="primary"
            size="small"
            icon={<EditOutlined />}
            // onClick={() => onEdit(record)}
          >
            Replace
          </Button>
          <Button
            danger
            type="primary"
            size="small"
            icon={<DeleteOutlined />}
            // onClick={() => onDelete(record)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return <Table columns={columns} dataSource={users} rowKey="id" />;
};

export default UserTable;
