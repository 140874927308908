import React, { useState } from 'react';
import { Button, notification, Select } from 'antd';
import { useQuery } from '@apollo/client';
import { User } from '../../graphql/types';
import { GET_USERS } from '../../graphql/queries';
import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} from '../../hooks/useMutations';
import UserTable from '../../components/UserTable';
import UserFormModal from '../../components/UserFormModal';

import { MapContainer, TileLayer, Marker, Popup, CircleMarker } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
//import stringHash from 'string-hash';

function hashString(str: string): number {
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 7) - hash) + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

function intToRGB(value: number, brightness: number): string {
  // Extract RGB components
  let r = (value >> 16) & 0xff;
  let g = (value >> 8) & 0xff;
  let b = value & 0xff;

  // Increase brightness
  r = Math.min(255, Math.round(r * brightness));
  g = Math.min(150, Math.round(g * 0.5)); // Reduce green component
  b = Math.min(150, Math.round(b * 0.5)); // Reduce blue component

  // Convert back to hexadecimal color code
  const hex = ((r << 16) | (g << 8) | b).toString(16);
  return "#" + "000000".substring(0, 6 - hex.length) + hex.toUpperCase();
}



function getUniqueColorForId(id: string): string {
  // Concatenate a unique suffix to the user ID
  const uniqueId = `${id}-${Date.now()}`;
  // Generate hash and convert it to color
  const colorValue = hashString(uniqueId);
  return intToRGB(colorValue, 1.5); // Adjust the brightness factor as needed (e.g., 1.5)
}



function SampleData() {
  const [visible, setVisible] = useState(false);
  const [editingUser, setEditingUser] = useState<User | undefined>(undefined);
  const [showPoints, setShowPoints] = useState(true); // State to toggle points visibility
  const [selectedOption, setSelectedOption] = useState('Wells RVP'); // State for selected dropdown option

  const { loading, error, data } = useQuery(GET_USERS);

  const [createUser] = useCreateUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();

  const showModal = (editingUser?: User) => {
    setEditingUser(editingUser);
    setVisible(true);
  };

  const handleCreate = async (values: User) => {
    try {
      const { data } = await createUser({ variables: { newUser: values } });
      if (data?.createUser) {
        setVisible(false);
        notification.success({ message: 'User added successfully!' });
      }
    } catch (error) {
      notification.error({ message: 'Adding user failed!' });
    }
  };

  const handleUpdate = async (values: User) => {
    try {
      const { data } = await updateUser({
        variables: { id: editingUser?.id || '', newUser: values },
      });
      if (data?.updateUser) {
        setVisible(false);
        notification.success({ message: 'Update successful!' });
      }
    } catch (error) {
      notification.error({ message: 'Update failed!' });
    }
  };

  const handleUpdateOrCreate = async (values: User, createMode: boolean) => {
    if (createMode) handleCreate(values);
    else handleUpdate(values);
  };

  const handleDelete = async (currentUser: User) => {
    try {
      const { data } = await deleteUser({
        variables: { id: currentUser?.id || '' },
      });
      if (data?.deleteUser) {
        setVisible(false);
        notification.success({ message: 'Deletion successful!' });
      }
    } catch (error) {
      notification.error({ message: 'The record was not deleted!' });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const users = data?.users ?? [];

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        <MapContainer center={[53.9333, -115.0000]} zoom={6} style={{ height: '800px' }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {showPoints && users.map((user: User) => (
            <CircleMarker key={user.user_name} center={[user.age, Number(user.title)]} radius={5} color={getUniqueColorForId(user.user_name)}>
              <Popup>
                {user.name}
              </Popup>
            </CircleMarker>
          ))}
        </MapContainer>
        <Button onClick={() => setShowPoints(!showPoints)}>
          {showPoints ? 'Hide Points' : 'Show Points'}
        </Button>
      </div>
      <div style={{ flex: 1 }}>
      <div style={{ marginBottom: '1rem' }}> {/* Add margin to separate the select and button */}
          <Select defaultValue={selectedOption} onChange={(value) => setSelectedOption(value)}>
            <Select.Option value="Wells RVP">Wells RVP</Select.Option>
            <Select.Option value="Pipelines RVP">Pipelines RVP</Select.Option>
            <Select.Option value="Facilities RVP">Facilities RVP</Select.Option>
          </Select>
        
          <Button type="primary"  style={{ marginLeft: '0.5rem' }}>
          Sampling
        </Button>

        <Button type="primary"  style={{ marginLeft: '6rem', backgroundColor: '#ffbf00' }}>
          Update Sample Size & Population
        </Button>
        <Button type="primary"  style={{ marginLeft: '0.2rem', backgroundColor: 'green' }}>
          Send to VPT
        </Button>
        </div>
        <UserTable users={users} onEdit={showModal} onDelete={handleDelete} />
        {visible && (
          <UserFormModal
            visible={visible}
            initialValues={editingUser as User}
            onSubmit={handleUpdateOrCreate}
            onCancel={() => setVisible(false)}
          />
        )}
      </div>
    </div>
  );
}

export default SampleData;
