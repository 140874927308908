// components/UserFormModal.tsx
import React, { useEffect } from 'react';
import { Modal, Form, Input, InputNumber } from 'antd';
import { User } from '../graphql/types';
import { Store } from 'antd/es/form/interface';

type UserFormModalProps = {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: User, createMode: boolean) => void;
  initialValues: User | null;
};

const UserFormModal: React.FC<UserFormModalProps> = ({
  visible,
  onCancel,
  onSubmit,
  initialValues,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [form, initialValues]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      onSubmit(values, !initialValues);
    } catch (error) {
      console.log('Validation error:', error);
    }
  };

  return (
    <Modal
      title={initialValues ? 'Edit User' : 'Add User'}
      open={visible}
      onOk={handleOk}
      onCancel={onCancel}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues as Store}
      >
        <Form.Item
          name="user_name"
          label="Username"
          rules={[{ required: true, message: 'Username is required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="age"
          label="Age"
          rules={[{ required: true, message: 'Age is required' }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item name="title" label="Title">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserFormModal;
