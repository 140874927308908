
import moment from 'moment';
import * as XLSX from 'xlsx';
import { RVP_Final_Program_Strata} from '../graphql/types';
const fileSaver = require('file-saver');

const RvpFinalProgramsExport = (rvpFinalPrograms: RVP_Final_Program_Strata[]) => {
  const strataData = rvpFinalPrograms.flatMap((program) => {
    return program.strata.map((stratum) => {
      return {
        Program: program.program,
        'Hazard Type': program.hazard_type,
        'Resource Type': program.resource_type,
        'Life Cycle Stage': program.life_cycle_stage,
        'Event Category': program.event_category,
        Discipline: program.discipline,
        Population: program.population,
        'Sample Size Total': program.sample_size_total,
        'Average Confidence Level': program.average_confidence_level,
        'Variables in Strata': program.variables_in_strata,
        'Extraction Details': program.extraction_details,
        Staff: program.staff,
        'FY Start Date': program.fiscal_year_start_date,
        'Program Comment': program.comment,
        'SME Responsible': program.sme_responsible,
        'Approved By': program.approved_by,
        'Approval Date': program.approval_date ? moment(Number(program.approval_date)).format('YYYY-MM-DD') : '',
        'Created by': program.row_created_by,
        'Create Date': program.row_create_date_time ? moment(Number(program.row_create_date_time)).format('YYYY-MM-DD') : '',
        'Modified by': program.row_modified_by,
        'Modify Date': program.row_modify_date_time ? moment(Number(program.row_modify_date_time)).format('YYYY-MM-DD') : '',
        'Stratum ID': stratum.stratum_id,
        Location: stratum.location,
        Operator: stratum.operator,
        Activity: stratum.activity,
        'Stratum Population': stratum.stratum_population,
        'Confidence Level': stratum.confidence_level,
        'Sample Proportion': stratum.sample_proportion,
        'Margin of Error': stratum.margin_of_error,
        'Extraction Method': stratum.extraction_method,
        'Extraction Frequency': stratum.extraction_frequency,
        Comment: stratum.comment,
        'Z Value': stratum.z_value,
        'Sample Size X': stratum.sample_size_x,
        'Sample Size': stratum.sample_size,
        'Percent of Population': stratum.percent_of_population,
        'Samples per Extraction': stratum.samples_per_extraction,
        'Sample Extraction Rate': stratum.sample_extraction_rate,
        'Statistical Statement': stratum.statistical_statement,
        'Stratum Created by': stratum.row_created_by,
        'Stratum Create Date': stratum.row_create_date_time ? moment(Number(stratum.row_create_date_time)).format('YYYY-MM-DD') : '',
        'Stratum Modified by': stratum.row_modified_by,
        'Stratum Modify Date': stratum.row_modify_date_time ? moment(Number(stratum.row_modify_date_time)).format('YYYY-MM-DD') : '',
      };
    });
  });

  const worksheet = XLSX.utils.json_to_sheet(strataData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'RVP Final Program and Strata');

  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  fileSaver.saveAs(excelData, 'rvp_final_programs_and_strata.xlsx');
};

export default RvpFinalProgramsExport;
