import * as XLSX from 'xlsx';
import { RvpDraftProgramInput, RvpDraftStratumInput } from '../../graphql/types';
import { v4 as uuidv4 } from 'uuid';
import config from '../../config';

interface ExcelData {
  programs: RvpDraftProgramInput[];
  strata: RvpDraftStratumInput[];
}

export function ExcelReader(file:File): Promise<ExcelData>  {
  // @ts-ignore
  const idTokenClaims = JSON.parse(localStorage.getItem(config.tokenClaimsKey));
  const userEmail = idTokenClaims.preferred_username;

  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = (e: ProgressEvent<FileReader>) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const rows:string[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const headers: string[] = rows[0];
      const programs = [];

      // Remove empty rows from the end
      let lastNonEmptyRow = rows.length - 1;
      for (let i = rows.length - 1; i >= 0; i--) {
        const isEmptyRow = rows[i].every((value) => value === '');
        if (isEmptyRow) {
          lastNonEmptyRow--;
        } else {
          break;
        }
      }
      const nonEmptyRows = rows.slice(0, lastNonEmptyRow + 1);

      for (let i = 1; i < nonEmptyRows.length; i++) {
        const program: any = {};
        for (let j = 0; j < headers.length; j++) {
          program[headers[j]] = nonEmptyRows[i][j];
        }
        programs.push(program);
      }

      // for (let i = 1; i < 2; i++) {
      //   const program: any = {};
      //   for (let j = 0; j < headers.length; j++) {
      //     program[headers[j]] = rows[i][j];
      //   }
      //   programs.push(program);
      // }

      const uuid = uuidv4();
      const formattedPrograms = programs.map((program) => {
        const hazardType = program['Hazard Type'];
        let hazardTypeFormatted = hazardType;
      
        switch (hazardType && hazardType.toLowerCase().substring(0, 2)) {
          case 'pi':
            hazardTypeFormatted = 'Pipeline';
            break;
          case 'we':
            hazardTypeFormatted = 'Well';
            break;
          case 'fa':
            hazardTypeFormatted = 'Facility';
            break;
          case 'mo':
            hazardTypeFormatted = "Facility,Geophysical,Pipeline,Well";
            break;
          default:
            hazardTypeFormatted = hazardType;
            break;
        }
      
        return {
          idSk: uuid,
          program: program['Program'],
          hazard_type: hazardTypeFormatted || "Facility,Geophysical,Pipeline,Well",
          resource_type: program['Resource Type'] || "Coal (in situ),Coal (mineable),Gas,Geothermal,Mineral (brine-hosted),Mineral (rock-hosted),Oil,Oil Sands (in situ),Oil Sands (mineable)",
          life_cycle_stage: program['Life Cycle Phase'],
          event_category: program['Event Category'] && program['Event Category'].startsWith('Exist')
    ? 'Existing infrastructure' : program['Event Category'] || "Application,Notification,Submission,Existing infrastructure,AER-Generated",
          discipline: program['Discipline'],
          variables_in_strata: program['Variables in Strata'],
          extraction_details: program['Extraction Details'],
          staff: program['Staff'],
          fiscal_year_start_date: '2023-04-01',
          comment: "",
          row_created_by: userEmail,
          row_modified_by: "",
        };
      });
      

      const formattedStrata = programs.map((program) => ({
        idSk: "",
        rvp_draft_id_sk: uuid,
        stratum_id: program['Stratum ID'],
        location: program['Location'],
        operator: program['Operator'],
        activity: program['Activity'],
        stratum_population: Math.round(program['Stratum Population (Volume)']),
        confidence_level: Math.round(parseFloat(program['Confidence Level'])*100),
        sample_proportion: Math.round(parseFloat(program['Sample Proportion'])*100),
        margin_of_error: Math.round(parseFloat(program['Margin of Error'])*100),
        extraction_method: program['Extraction Method'],
        extraction_frequency: program['Extraction Frequency'],
        comment: "", //program['comment'],
        row_created_by: "Frank Parolin", //program['row_created_by'],
        //row_create_date_time: "", //new Date(program['row_create_date_time']),
        row_modified_by: "", //program['row_modified_by'],
        //row_modify_data_time: "", // new Date(program['row_modify_data_time']),
      }));

      const excelData: ExcelData = {
        programs: formattedPrograms,
        strata: formattedStrata,
      };

      resolve(excelData);
    };

    fileReader.onerror = (e) => {
      reject(e);
    };

    fileReader.readAsBinaryString(file);
  });
}
