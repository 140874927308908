// components/UserFormModal.tsx
import React, { useEffect, useMemo } from 'react';
import { Modal, Form, Input, InputNumber, Select } from 'antd';
import { RVP_Draft_Stratum } from '../graphql/types';
import { Store } from 'antd/es/form/interface';

import { useQuery } from '@apollo/client';
import {GET_RVP_MAX_VALUE, GET_L_CONFIDENCE_LEVEL, GET_L_EXTRACTION_FREQUENCY, GET_L_EXTRACTION_METHOD } from '../graphql/queries';
import config from '../config';

const { TextArea } = Input;

type RvpDraftStratumFormModalProps = {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (stratum: RVP_Draft_Stratum) => void;
  initialValues: RVP_Draft_Stratum;
};

const RvpDraftStratumFormModal: React.FC<RvpDraftStratumFormModalProps> = ({
  visible,
  onCancel,
  onSubmit,
  initialValues,
}) => {
  const [form] = Form.useForm();

  const { data: stratumMaxValueData, refetch } = useQuery(GET_RVP_MAX_VALUE, {
    variables: { discipline: initialValues ? initialValues.stratum_id : "SOIL" },
  });
  const rvpMaxStratumNumber = stratumMaxValueData?.rvpMaxStratumNumber.maxValue ?? 0;
 
  console.log('stratumMaxValueData:', stratumMaxValueData);
  console.log('rvpMaxStratumNumber:', rvpMaxStratumNumber);

  // const newInitialValues = initialValues ? {
  //   ...initialValues,
  //   stratum_id: initialValues.idSk ? initialValues.stratum_id : initialValues.stratum_id + "-" + (rvpMaxStratumNumber+1),
  // } : null;

  // @ts-ignore
  const idTokenClaims = JSON.parse(localStorage.getItem(config.tokenClaimsKey));
  const userEmail = idTokenClaims.preferred_username;
  console.log(userEmail);

  const newInitialValues = useMemo(() => {
    if (initialValues) {
      return {
        ...initialValues,
        stratum_id: initialValues.idSk ? initialValues.stratum_id : initialValues.stratum_id + "-" + (rvpMaxStratumNumber + 1),
        row_modified_by: initialValues.idSk ? userEmail : "",
        row_created_by: initialValues.idSk ? initialValues.row_created_by : userEmail,
      };
    } else {
      return null;
    }
  }, [initialValues, rvpMaxStratumNumber, userEmail]);


  useEffect(() => {
    form.resetFields();
    if (newInitialValues) {
      form.setFieldsValue({
        ...newInitialValues,
      });
    }
  }, [form, newInitialValues,]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      onSubmit(values);
      refetch();
    } catch (error) {
      console.log('Validation error:', error);
    }
  };


  const { data: confidenceLevelData } = useQuery(GET_L_CONFIDENCE_LEVEL);
  const confidenceLevels = confidenceLevelData?.lConfidences ?? [];
  const confidenceLevelOptions = confidenceLevels.map((level: any) => ({
    label: level.confidence_level,
    value: level.confidence_level,
  }));

  const { data: extractionMethodData } = useQuery(GET_L_EXTRACTION_METHOD);
  const extractionMethods = extractionMethodData?.lExtractionMethods ?? [];
  const extractionMethodOptions = extractionMethods.map((m: any) => ({
    label: m.extraction_method,
    value: m.extraction_method,
  }));


  const { data: extractionFrequencyData } = useQuery(GET_L_EXTRACTION_FREQUENCY);
  const extractionFrequencies = extractionFrequencyData?.lExtractionFrequencies ?? [];
  const extractionFrequencyOptions = extractionFrequencies.map((f: any) => ({
    label: f.extraction_frequency,
    value: f.extraction_frequency,
  }));

  return (
    <Modal
      title={initialValues.idSk ? 'Edit RV Draft Stratum' : 'Add RV Draft Stratum'}
      open={visible}
      onOk={handleOk}
      onCancel={onCancel}
      maskClosable={false}
      width={1000}
    //bodyStyle={{height: 700}}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={newInitialValues as Store}

      >
        <Form.Item style={{ display: 'inline-block' }}
          name="stratum_id"
          label="Stratum Id"
          rules={[{ required: true, message: 'Stratum Id is required' }]}
        >
          <Input style={{ width: 200 }} />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block', marginLeft: '30px' }}
          name="location"
          label="Location"
        >
          <TextArea rows={1} style={{ width: 700 }} />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block' }}
          name="operator"
          label="Operator"
        >
          <TextArea rows={1} style={{ width: 200 }} />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block', marginLeft: '30px' }}
          name="activity"
          label="Activity"
        >
          <TextArea rows={1} style={{ width: 700 }} />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block' }}
          name="stratum_population"
          label="Stratum Population"
          rules={[{ required: true, message: 'Stratum Population is required' }]}
        >
          <InputNumber style={{ width: 200 }} />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block', marginLeft: '30px' }}
          name="confidence_level"
          label="Confidence Level (%)"
          rules={[{ required: true, message: 'confidence Level is required' }]}
        >
          <Select
            style={{ width: 185 }}
            options={confidenceLevelOptions} />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block', marginLeft: '30px' }}
          name="sample_proportion"
          label="Sample Proportion (%)"
          rules={[{ required: true, message: 'Sample Proportion is required' }]}
        >
          <InputNumber style={{ width: 230, backgroundColor: "#D3D3D3" }} />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block', marginLeft: '30px' }}
          name="margin_of_error"
          label="Margin of Error (%)"
          rules={[{ required: true, message: 'Margin of Error is required' }]}
        >
          <InputNumber style={{ width: 230, backgroundColor: "#D3D3D3" }} />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block' }}
          name="extraction_method"
          label="Extraction Method"
          rules={[{ required: true, message: 'Extraction Method is required' }]}
        >
          <Select
            style={{ width: 200 }}
            options={extractionMethodOptions}
            // onChange={(value: string) => {
            //   if (value === 'Simple random sampling') {
            //     form.setFieldsValue({ extraction_frequency: 'Annually' }); // Set the value using an object
            //   }
            // }
            // }

          />
        </Form.Item>
        <Form.Item dependencies={["extraction_method"]} style={{ display: 'inline-block' }}>
          {({ getFieldValue }) => (
            <Form.Item
              style={{ display: 'inline-block', marginLeft: '30px' }}
              name="extraction_frequency"
              label="Extraction Frequency"
              rules={[{ required: true, message: 'Extraction Frequency is required' }]}
            >
              <Select
                style={{ width: 190 }}
                options={extractionFrequencyOptions}
                //disabled={getFieldValue("extraction_method") === 'Simple random sampling'}
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item name="idSk" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="rvp_draft_id_sk" hidden>
          <Input />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block', marginLeft: '30px' }}
          name="comment"
          label="Comments/changes"
        >
          <TextArea rows={1} style={{ width: 480 }} />
        </Form.Item>

        <Form.Item name="row_created_by" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="row_modified_by" hidden>
          <Input />
        </Form.Item>
        
      </Form>

    </Modal>
  );
};

export default RvpDraftStratumFormModal;
