// src/graphql/queries.ts
import { gql } from 'apollo-boost';
//import { User } from './types';

export const GET_USERS = gql`
  query getUsers {
    users {
      id
      user_name
      name
      age
      title
    }
  }
`;

export const GET_USER = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      id
      user_name
      name
      age
      title
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($newUser: UserInput!) {
    createUser(newUser: $newUser) {
      id
      user_name
      name
      age
      title
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $newUser: UserInput!) {
    updateUser(id: $id, newUser: $newUser) {
      id
      user_name
      name
      age
      title
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;


export const GET_RVP_DRAFT_PROGRAM = gql`
  query getRvpDraftProgram($idSk: String!) {
  rvp_draft_program(idSk: $idSk) {
    idSk
    program
    hazard_type
    resource_type
    life_cycle_stage
    event_category
    discipline
    population
    sample_size_total
    average_confidence_level
    variables_in_strata
    extraction_details
    staff
    fiscal_year_start_date
    comment
    row_created_by
    row_create_date_time
    row_modified_by
    row_modify_date_time
  }
}
`;

export const GET_RVP_DRAFT_PROGRAMS = gql`
  query getRvpDraftPrograms {
  rvpDraftPrograms {
    idSk
    program
    hazard_type
    resource_type
    life_cycle_stage
    event_category
    discipline
    population
    sample_size_total
    average_confidence_level
    variables_in_strata
    extraction_details
    staff
    fiscal_year_start_date
    comment
    row_created_by
    row_create_date_time
    row_modified_by
    row_modify_date_time
  }
}
`;

export const GET_RVP_DRAFT_PROGRAMS_STRATA = gql`
  query getRvpDraftProgramsStrata {
  rvpDraftProgramsStrata {
    idSk
    program
    hazard_type
    resource_type
    life_cycle_stage
    event_category
    discipline
    population
    sample_size_total
    average_confidence_level
    variables_in_strata
    extraction_details
    staff
    fiscal_year_start_date
    comment
    row_created_by
    row_create_date_time
    row_modified_by
    row_modify_date_time
    strata {
      idSk
      rvp_draft_id_sk
      stratum_id
      location
      operator
      activity
      stratum_population
      confidence_level
      sample_proportion
      margin_of_error
      extraction_method
      extraction_frequency
      comment
      row_created_by
      row_create_date_time
      row_modified_by
      row_modify_date_time
      z_value
      sample_size_x
      sample_size
      percent_of_population
      samples_per_extraction
      sample_extraction_rate
      statistical_statement
    }
  }
}
`;

export const CREATE_RvpDraftProgram = gql`
  mutation CreateRvpDraftProgram($newRvpDraftProgram: RvpDraftProgramInput) {
  createRvpDraftProgram(newRvpDraftProgram: $newRvpDraftProgram) {
    idSk
    program
    hazard_type
    resource_type
    life_cycle_stage
    event_category
    discipline
    population
    sample_size_total
    average_confidence_level
    variables_in_strata
    extraction_details
    staff
    fiscal_year_start_date
    comment
    row_created_by
    row_create_date_time
    row_modified_by
    row_modify_date_time
  }
}
`;

export const UPDATE_RvpDraftProgram = gql`
  mutation UpdateRvpDraftProgram($newRvpDraftProgram: RvpDraftProgramInput) {
  updateRvpDraftProgram(newRvpDraftProgram: $newRvpDraftProgram) {
    idSk
    program
    hazard_type
    resource_type
    life_cycle_stage
    event_category
    discipline
    population
    sample_size_total
    average_confidence_level
    variables_in_strata
    extraction_details
    staff
    fiscal_year_start_date
    comment
    row_created_by
    row_create_date_time
    row_modified_by
    row_modify_date_time
  }
}
`;

export const DELETE_RvpDraftProgram = gql`
 mutation DeleteRvpDraftProgram($idSk: String) {
  deleteRvpDraftProgram(idSk: $idSk)
}
`;

////////////////////////////
//  Reference Tables //////
///////////////////////////
export const GET_L_CONFIDENCE_LEVEL = gql`
  query getConfidences {
    lConfidences {
      confidence_level
    }
  }
`;
export const GET_L_PROGRAM = gql`
  query getPrograms {
    lPrograms {
      program
    }
  }
`;
export const GET_L_LIFE_CYCLE_STAGE = gql`
  query getLifeCycleStages {
    lLifeCycles {
      life_cycle_stage
      order
    }
  }
`;
export const GET_L_HAZARD_TYPE = gql`
  query getHazards {
    lHazards {
      hazard_type
    }
  }
`;
export const GET_L_RESOURCE_TYPE = gql`
  query getResources {
    lResources {
      resource_type
    }
  }
`;
export const GET_L_EVENT_CATEGORY = gql`
  query getEvents {
    lEvents {
      event_category
    }
  }
`;
export const GET_L_DISCIPLINE = gql`
  query getDisciplines {
    lDisciplines {
      discipline
      description
    }
  }
`;
export const GET_L_STAFF = gql`
  query getStaffs {
    lStaffs {
      staff
    }
  }
`;
export const GET_L_EXTRACTION_FREQUENCY = gql`
query getExtractionFrequencies {
  lExtractionFrequencies {
    extraction_frequency
    order
  }
}
`;
export const GET_L_EXTRACTION_METHOD = gql`
query getExtractionMethods {
  lExtractionMethods {
    extraction_method
  }
}
`;

////
export const GET_RVP_MAX_VALUE = gql`
query rvpMaxStratumNumber($discipline: String!) {
  rvpMaxStratumNumber(discipline: $discipline) {
    maxValue
  }
}
`;

////////////////////////////
///  TVP Reference Tables ///
///////////////////////////
export const GET_L_REASON = gql`
query getReasons {
  lReasons {
    reason
    annotation
  }
}
`;

export const GET_L_INTENT = gql`
query getIntents {
  lIntents {
    intent
  }
}
`;

export const GET_L_RISK = gql`
query getRisks {
  lRisks {
    risk
    annotation
  }
}
`;

export const GET_L_CONSEQUENCE = gql`
query getConsequences {
  lConsequences {
    consequence
    annotation
  }
}
`;

export const GET_L_INFORMATIONGAP = gql`
query getInformationGaps {
  lInformationGaps {
    information_gap
  }
}
`;

export const GET_L_TARGETIDENTIFICATION = gql`
query gettargetIdentifications {
  lTargetIdentifications {
    target_identification
    annotation
  }
}
`;

export const GET_L_NUMBEROFVERIFICATION = gql`
query getnumberOfVerifications {
  lNumberOfVerifications {
    number_of_verification
  }
}
`;

export const GET_L_NUMBEROFWORKDAY = gql`
query getnumberOfWorkdays {
  lNumberOfWorkdays {
    number_of_workday
  }
}
`;

export const GET_L_SYSTEM = gql`
query getsystems {
  lSystems {
    system
  }
}
`;
////////////////////////////
///  TVP Draft Tables /////
///////////////////////////
export const GET_TVP_DRAFT_PROGRAMS = gql`
  query getTvpDraftPrograms {
  tvpDraftPrograms {
    Id
    ProgramName
    RegulatoryProblem
    RequirementsToVerify
    Reason
    Intent
    Risk
    Consequence
    InformationGap
    HazardType
    ResourceType
    LifeCycleStage
    Discipline
    TargetIdentification
    TargetDetail
    SmeResponsible
    PreferredStartDate
    PreferredEndDate
    Staff
    NumberOfVerification
    NumberOfWorkday
    System
    FiscalYearStartDate
    Creator
    CreationInstant
    Annotation
    Modifier
    ModificationInstant
  }
}
`;

////////////////////////////
///  TVP Final Tables /////
///////////////////////////
export const GET_TVP_FINAL_PROGRAMS = gql`
  query getTvpFinalPrograms {
  tvpFinalPrograms {
    Id
    ProgramName
    RegulatoryProblem
    RequirementsToVerify
    Reason
    Intent
    Risk
    Consequence
    InformationGap
    HazardType
    ResourceType
    LifeCycleStage
    Discipline
    TargetIdentification
    TargetDetail
    SmeResponsible
    PreferredStartDate
    PreferredEndDate
    Staff
    NumberOfVerification
    NumberOfWorkday
    System
    FiscalYearStartDate
    Creator
    CreationInstant
    Annotation
    Modifier
    ModificationInstant
    ApprovedBy
    ApprovalDate
    IsActive
  }
}
`;

////////////////////////////
///  RVP Final Tables ////
///////////////////////////

export const GET_RVP_FINAL_PROGRAM = gql`
  query getRvpFinalProgram($idSk: String!) {
  rvp_final_program(idSk: $idSk) {
    idSk
    program
    hazard_type
    resource_type
    life_cycle_stage
    event_category
    discipline
    population
    sample_size_total
    average_confidence_level
    variables_in_strata
    extraction_details
    staff
    fiscal_year_start_date
    comment
    sme_responsible
    approved_by
	  approval_date
    row_created_by
    row_create_date_time
    row_modified_by
    row_modify_date_time
    active
  }
}
`;

export const GET_RVP_FINAL_PROGRAMS = gql`
  query getRvpFinalPrograms {
  rvpFinalPrograms {
    idSk
    program
    hazard_type
    resource_type
    life_cycle_stage
    event_category
    discipline
    population
    sample_size_total
    average_confidence_level
    variables_in_strata
    extraction_details
    staff
    fiscal_year_start_date
    comment
    sme_responsible
    approved_by
	  approval_date
    row_created_by
    row_create_date_time
    row_modified_by
    row_modify_date_time
    active
  }
}
`;

export const GET_RVP_FINAL_PROGRAMS_STRATA = gql`
  query getRvpFinalProgramsStrata {
  rvpFinalProgramsStrata {
    idSk
    program
    hazard_type
    resource_type
    life_cycle_stage
    event_category
    discipline
    population
    sample_size_total
    average_confidence_level
    variables_in_strata
    extraction_details
    staff
    fiscal_year_start_date
    comment
    sme_responsible
    approved_by
	  approval_date
    row_created_by
    row_create_date_time
    row_modified_by
    row_modify_date_time
    active
    strata {
      idSk
      rvp_final_id_sk
      stratum_id
      location
      operator
      activity
      stratum_population
      confidence_level
      sample_proportion
      margin_of_error
      extraction_method
      extraction_frequency
      comment
      row_created_by
      row_create_date_time
      row_modified_by
      row_modify_date_time
      z_value
      sample_size_x
      sample_size
      percent_of_population
      samples_per_extraction
      sample_extraction_rate
      statistical_statement
      active
    }
  }
}
`;

export const CREATE_RvpFinalProgram = gql`
  mutation CreateRvpFinalProgram($newRvpFinalProgram: RvpFinalProgramInput) {
  createRvpFinalProgram(newRvpFinalProgram: $newRvpFinalProgram) {
    idSk
    program
    hazard_type
    resource_type
    life_cycle_stage
    event_category
    discipline
    population
    sample_size_total
    average_confidence_level
    variables_in_strata
    extraction_details
    staff
    fiscal_year_start_date
    comment
    approved_by
	  approval_date
    row_created_by
    row_create_date_time
    row_modified_by
    row_modify_date_time
    active
  }
}
`;

